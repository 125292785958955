import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { JwtAuthService } from '../../core/services/auth.service';
// import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-topbar3',
  templateUrl: './topbar3.component.html',
  styleUrls: ['./topbar3.component.scss'],
  template: `
    <img [src]="imageUrl">
  `,
})

/**
 * Topbar component
 */
export class Topbar3Component implements OnInit {

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;

  imageUrl: string;
  orderDetails: any[] = [];

  orderActive: number = 0;
  orderAccepted: number = 0;
  orderReviews: number = 0;
  wallet_money: number = 0;
  walletDetails: any = [];

  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: JwtAuthService,
              public languageService: LanguageService,
              public translate: TranslateService,
              public _cookiesService: CookieService) {
                this.userDetails = {};
                this.orderActive = 0;
                this.orderAccepted = 0;
                this.orderReviews = 0;
                this.wallet_money = 0;
                this.imageUrl = authService.getImageUrl();
  }

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Arabic', flag: 'assets/images/flags/arabic.jpg', lang: 'ar' },
  ];


  openMobileMenu: boolean;
  userDetails: any;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    this.authService.getUserDetails().subscribe(
      userDetails => {
        this.userDetails = userDetails;
        console.log(this.userDetails);
        this.userDetails.image = this.imageUrl + this.userDetails.image;
        // Do something with the user details
        this.authService.getUserWallet(this.userDetails.id).subscribe(
          walletDetails => {
            console.log("this.walletDetails");
            this.walletDetails = walletDetails;
            console.log(this.walletDetails);
            if (parseFloat(this.walletDetails.balance) > 30){
              this.wallet_money = 0;
            } else {
              this.wallet_money = 1;
            }
          },
            error => {
              console.log(error);
              // Handle the error
              if(this.walletDetails.length === 0){
                // console.log("ok");
                const formData = new FormData();
                formData.append('created_by_wallet.email', this.userDetails.email);
                this.authService.createWallet(formData).subscribe(
                  (result: boolean) => {
                    console.log('Customer Wallet Created:', result);
                    this.ngOnInit();
                    
                  },
                  (error) => {
                    console.log('Customer Wallet Create failed:', error);
                  }
                );
              } 
          }
        );

        this.authService.getUserOrders(this.userDetails.id).subscribe(
          orderDetails => {
            console.log("this.orderDetails");
            this.orderDetails = orderDetails;
            console.log(this.orderDetails);
            this.orderActive = 0;
            this.orderAccepted = 0;
            this.orderReviews = 0;
            this.orderDetails.forEach(order => {
              console.log("testing");
              console.log(order);
              
              if (order.order_status == "active") {
                this.orderActive += 1;
              }
              else if (order.order_status == "accepted") {
                this.orderAccepted += 1;
              }
              else if (order.order_status == "completed" && order.vendor_review === null) {
                this.orderReviews += 1;
              }
            });
            console.log(this.orderActive);
             
          },
            error => {
              console.log(error);
              // Handle the error
            }
          );

          
      },
      error => {
        console.log(error);
        // Handle the error
      }
    );

  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    if (environment.defaultauth === 'firebase') {
      this.authService.logout();
    } else {
      this.authService.logout();
    }
    this.router.navigate(['/account/login']);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
}
