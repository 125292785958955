import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, map, of } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class JwtAuthService {

  // for development purpose
  // public baseUrl = 'https://backend.zohdy.com';
  // public baseUrl = 'http://64.225.58.10:8001';
  // for local machine purpose
  // public baseUrl = 'http://127.0.0.1:8000';  
  public baseUrl = 'https://zohdy-176709940cda.herokuapp.com';
  public jwtHelper = new JwtHelperService();

  constructor(private http: HttpClient, private router: Router) { }

  login(email: string, password: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/auth/login`, { email, password });
  }

  verify(otp: string): Observable<any> {
    let email = localStorage.getItem('email');
    return this.http.post(`${this.baseUrl}/api/auth/verify-otp`, { email, otp });


  }

  verifyPhoneOTP(otp: string, id: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/auth/verify-phone-otp`, { id, otp });
  }

  sendPhoneOTP(id: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/auth/send-phone-otp`, { id });
  }

  logout(): void {
    localStorage.removeItem('token');
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  isLoggedIn(): boolean {
    const token = this.getToken();
    return token ? !this.jwtHelper.isTokenExpired(token) : false;
  }
  
  setAuthHeaders(): HttpHeaders {
    const token = this.getToken();
    let headers = new HttpHeaders();
    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    return headers;
  }

  getWishlist(): Observable<any> {
    const headers = this.setAuthHeaders();
    const wishlistUrl = `${this.baseUrl}/api/wishlist/`; // Adjust this URL to your API endpoint
    return this.http.get(wishlistUrl, { headers });
  }

  removeFromWishlist(serviceId: number): Observable<any> {
    const headers = this.setAuthHeaders(); // Make sure this includes the Authorization header
    const URL = `${this.baseUrl}/api/wishlist/remove/${serviceId}/`;
    return this.http.post(URL, {}, { headers }); // Use POST as per your Django view setup
  }

  getUserDetails(): Observable<any> {
    const headers = this.setAuthHeaders();
    const loginUrl = `${this.baseUrl}/api/auth/profile`;
    return this.http.get<any>(loginUrl, { headers });
  }

  
 
  getAllUserDetails(): Observable<any> {
    const headers = this.setAuthHeaders();
    const allUsersUrl = `${this.baseUrl}/api/users`;
    return this.http.get<any>(allUsersUrl, { headers });
  }

  updateUserActive(user: any, user_id: any): Observable<any> {
    const headers = this.setAuthHeaders();
    const allUsersUrl = `${this.baseUrl}/api/users/${user_id}`;
    return this.http.put(allUsersUrl, user, { headers });
  }
 
  getImageUrl(): string {
    return this.baseUrl;
  }
  
  
  register(formDataCustomer: any) {
    const registerUrl = `${this.baseUrl}/api/auth/register`;
    return this.http.post(registerUrl, formDataCustomer)
    .subscribe(
      (response) => {
        // console.log('Registration successful:', response);
        this.router.navigate(['/account/verify']);
        localStorage.setItem('email', formDataCustomer.get('email'));
        Swal.fire('Success!', 'Please Varify Your Otp', 'warning');


      },
      (error) => {
        Swal.fire('Errors!', 'Registration failed : ' + error, 'error');
        // console.log('Registration failed:', error);
      }
    );
  }

  getUserAddress(user_id: any): Observable<any> {
    const headers = this.setAuthHeaders();
    const allUsersUrl = `${this.baseUrl}/api/address/${user_id}`;
    return this.http.get<any>(allUsersUrl, { headers });
  }

  createUserAddress(formAddress: any) : Observable<boolean> {
      const headers = this.setAuthHeaders();
      const AddressUrl = `${this.baseUrl}/api/address/create`;
      return this.http.post(AddressUrl, formAddress, { headers })
      .pipe(
        map((response) => {
          console.log('Address Add successful:', response);
          return true;
        },
        (error) => {
          console.log('Address failed:', error);
          return of(false);
        })
      );
    
  }

  addToWishlist(serviceId: number): Observable<any> {
    const headers = this.setAuthHeaders(); 
    const URL = `${this.baseUrl}/api/wishlist/add/${serviceId}/`;
    return this.http.post(URL, {}, { headers });
  }

  updateAddress(address: any, address_id: any): Observable<boolean> {
    const headers = this.setAuthHeaders();
    const addressUrl = `${this.baseUrl}/api/address/${address_id}/update`;
    return this.http.put(addressUrl, address, { headers })
    .pipe(
      map((response) => {
        console.log('Address Update successful:', response);
        return true;
      },
      (error) => {
        console.log('Address failed:', error);
        return of(false);
      })
    );
  }

  deleteAddress(address_id: any): Observable<boolean> {
    const headers = this.setAuthHeaders();
    const addressUrl = `${this.baseUrl}/api/address/delete/${address_id}`;
    return this.http.delete(addressUrl, { headers })
    .pipe(
      map((response) => {
        console.log('Address Delete successful:', response);
        
        return true;
      },
      (error) => {
        console.log('Address failed:', error);
        return of(false);
      })
    );
  }


  getUserAvailable(user_id: any): Observable<any> {
    const headers = this.setAuthHeaders();
    const allUsersUrl = `${this.baseUrl}/api/available/${user_id}`;
    return this.http.get<any>(allUsersUrl, { headers });
  }

  updateAvailable(available: any, available_id: any): Observable<boolean> {
    const headers = this.setAuthHeaders();
    const addressUrl = `${this.baseUrl}/api/available/${available_id}/update`;
    return this.http.put(addressUrl, available, { headers })
    .pipe(
      map((response) => {
        console.log('Vendors Available Time Update successful:', response);
        return true;
      },
      (error) => {
        console.log('Vendors Available Time failed:', error);
        return of(false);
      })
    );
  }

  createAvailable(available: any): Observable<boolean> {
    const headers = this.setAuthHeaders();
    const addressUrl = `${this.baseUrl}/api/available/create`;
    return this.http.post(addressUrl, available, { headers })
    .pipe(
      map((response) => {
        console.log('Vendors Available Time Create successful:', response);
        return true;
      },
      (error) => {
        console.log('Vendors Available Time create failed:', error);
        return of(false);
      })
    );
  }

  getUserWallet(user_id: any): Observable<any> {
    const headers = this.setAuthHeaders();
    const allUsersUrl = `${this.baseUrl}/api/wallet/${user_id}`;
    return this.http.get<any>(allUsersUrl, { headers });
  }

  

  updateWallet(user_id: any, formData: any,): Observable<any> {
    const headers = this.setAuthHeaders();
    const allUsersUrl = `${this.baseUrl}/api/wallet/${user_id}/update`;
    return this.http.patch<any>(allUsersUrl, formData, { headers });
  }
  
  // initiateStripePayment(amount: number): Observable<string> {
  //   return this.http.post<string>(`${this.baseUrl}/api/initiate-payment`, { amount });
  // }

  createCheckoutSession(amount: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/create-checkout-session`, {amount});
  }

  checkoutSuccess(sessionId: string): Observable<any> {
    const headers = this.setAuthHeaders();
    const checkoutSuccessUrl = `${this.baseUrl}/api/payment-success`;
    return this.http.post(checkoutSuccessUrl, { sessionId }, { headers })
    // return this.http.post(`${this.baseUrl}/api/payment-success`, {sessionId});
  }


  createWallet(wallet: any): Observable<boolean> {
    const headers = this.setAuthHeaders();
    const addressUrl = `${this.baseUrl}/api/wallet/create`;
    return this.http.post(addressUrl, wallet, { headers })
    .pipe(
      map((response) => {
        console.log('Wallet Create successful:', response);
        return true;
      },
      (error) => {
        console.log('Wallet create failed:', error);
        return of(false);
      })
    );
  }

  getUserTransaction(user_id: any): Observable<any> {
    const headers = this.setAuthHeaders();
    const allUsersUrl = `${this.baseUrl}/api/transaction/${user_id}`;
    return this.http.get<any>(allUsersUrl, { headers });
  }

  createBookingOrder(formData: any, method:any) : Observable<boolean> {
    const headers = this.setAuthHeaders();
    let Url = '';
    if (method === "cashondelivery") {
      Url = `${this.baseUrl}/api/order-payment/create2`;
    } else if (method === "wallet") {
      Url = `${this.baseUrl}/api/order-payment/create`;
    }
    
    return this.http.post(Url, formData, { headers })
    .pipe(
      map((response) => {
        console.log('Order Add successful:', response);
        return true;
      },
      (error) => {
        console.log('Order failed:', error);
        return of(false);
      })
    );
  
}


getUserOrders(user_id: any): Observable<any> {
  const headers = this.setAuthHeaders();
  const allUsersUrl = `${this.baseUrl}/api/orders/${user_id}`;
  return this.http.get<any>(allUsersUrl, { headers });
}

getOrdersDetails(): Observable<any> {
  const headers = this.setAuthHeaders();
  const URL = `${this.baseUrl}/api/orders`;
  return this.http.get<any>(URL, { headers });
}

getAcceptOrdersDetails(vendor_id): Observable<any> {
  const headers = this.setAuthHeaders();
  const URL = `${this.baseUrl}/api/orders/${vendor_id}/fetch`;
  return this.http.get<any>(URL, { headers });
}

getDoneOrdersDetails(vendor_id): Observable<any> {
  const headers = this.setAuthHeaders();
  const URL = `${this.baseUrl}/api/orders/${vendor_id}/done`;
  return this.http.get<any>(URL, { headers });
}



}
