import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgOtpInputModule } from  'ng-otp-input';

import {
  NgbAlertModule, NgbCarouselModule, NgbDropdownModule, NgbModalModule, NgbProgressbarModule,
  NgbTooltipModule, NgbPopoverModule, NgbPaginationModule, NgbNavModule, NgbAccordionModule,
  NgbCollapseModule, NgbToastModule, NgbRatingModule
} from '@ng-bootstrap/ng-bootstrap';


import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TranslateModule } from '@ngx-translate/core';

import { HomeRoutingModule } from './home-routing.module';
import { HomePageComponent } from './homepage/homepage.component';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [HomePageComponent],
  imports: [
    CommonModule,
    TranslateModule,
    CarouselModule,
    HomeRoutingModule,
    NgOtpInputModule,
    NgbCarouselModule,
    BrowserModule,
    NgbModule
    
  ]
})
export class HomeModule { }
