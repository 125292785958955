import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map, of } from 'rxjs';
import { JwtAuthService } from './auth.service';
import { Router, ActivatedRoute  } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class SubsctiptionService {



  constructor(private http: HttpClient, private auth: JwtAuthService, private router: Router, 
    private activatedRoute: ActivatedRoute) { }

  private baseUrl = this.auth.baseUrl;

  getAllSubscriptions(): Observable<any> {
    const headers = this.auth.setAuthHeaders();
    const subscriptionUrl = `${this.baseUrl}/api/subscription`;
    return this.http.get<any>(subscriptionUrl, { headers });
  }

//   getProductById(id: number): Observable<any> {
//     return this.http.get<any>(`${this.baseUrl}/${id}`);
//   }

  createSubscription(subscription: any): Observable<boolean> {
    const headers = this.auth.setAuthHeaders();
    const subscriptionUrl = `${this.baseUrl}/api/subscription/create`;
    return this.http.post(subscriptionUrl, subscription, { headers })
    .pipe(
      map((response) => {
        console.log('Subscription Add successful:', response);
        // modal.close('Close click')
        // this.router.navigate(['/subscription']);
        return true;
      },
      (error) => {
        console.log('Subscription failed:', error);
        return of(false);
      })
    );
  }

  updateSubscription(subscription: any, subscription_id: any): Observable<boolean> {
    const headers = this.auth.setAuthHeaders();
    const subscriptionUrl = `${this.baseUrl}/api/subscription/${subscription_id}/update`;
    return this.http.put(subscriptionUrl, subscription, { headers })
    .pipe(
      map((response) => {
        console.log('Subscription Update successful:', response);
        // modal.close('Close click')
        // this.router.navigate(['/subscription']);
        // this.refreshCurrentRoute();
        return true;
      },
      (error) => {
        console.log('Subscription failed:', error);
        return of(false);
      })
    );
  }

  deleteSubscription(subscription_id: any): Observable<boolean> {
    const headers = this.auth.setAuthHeaders();
    const subscriptionUrl = `${this.baseUrl}/api/subscription/delete/${subscription_id}`;
    return this.http.delete(subscriptionUrl, { headers })
    .pipe(
      map((response) => {
        console.log('Subscription Delete successful:', response);
        // modal.close('Close click')
        // this.router.navigate(['/subscription']);
        // this.refreshCurrentRoute();
        return true;
      },
      (error) => {
        console.log('Subscription failed:', error);
        return of(false);
      })
    );
  }




  getAllService(): Observable<any> {
    const headers = this.auth.setAuthHeaders();
    const ServiceUrl = `${this.baseUrl}/api/service`;
    return this.http.get<any>(ServiceUrl, { headers });
  }

  //   getProductById(id: number): Observable<any> {
  //     return this.http.get<any>(`${this.baseUrl}/${id}`);
  //   }

  createService(service: any): Observable<boolean> {
    const headers = this.auth.setAuthHeaders();
    const ServiceUrl = `${this.baseUrl}/api/service/create`;
    return this.http.post(ServiceUrl, service, { headers })
    .pipe(
      map((response) => {
        console.log('Service Add successful:', response);
        return true;
      },
      (error) => {
        console.log('Service failed:', error);
        return of(false);
      })
    );
  }

  updateService(service: any, service_id: any): Observable<boolean> {
    const headers = this.auth.setAuthHeaders();
    const ServiceUrl = `${this.baseUrl}/api/service/${service_id}/update`;
    return this.http.put(ServiceUrl, service, { headers })
    .pipe(
      map((response) => {
        console.log('Service Update successful:', response);
        return true;
      },
      (error) => {
        console.log('Service failed:', error);
        return of(false);
      })
    );
  }

  deleteService(service_id: any): Observable<boolean> {
    const headers = this.auth.setAuthHeaders();
    const ServiceUrl = `${this.baseUrl}/api/service/delete/${service_id}`;
    return this.http.delete(ServiceUrl, { headers })
    .pipe(
      map((response) => {
        console.log('Service Delete successful:', response);
        return true;
      },
      (error) => {
        console.log('Service failed:', error);
        return of(false);
      })
    );
  }


  createSubscriptionOrder(subscription: any): Observable<boolean> {
    const headers = this.auth.setAuthHeaders();
    const subscriptionUrl = `${this.baseUrl}/api/subscription_order/create`;
    return this.http.post(subscriptionUrl, subscription, { headers })
    .pipe(
      map((response) => {
        console.log('Subscription Order Add successful:', response);
        // modal.close('Close click')
        // this.router.navigate(['/subscription']);
        return true;
      },
      (error) => {
        console.log('Subscription Order failed:', error);
        return of(false);
      })
    );
  }

  createSubscriptionWalletOrder(subscription: any): Observable<boolean> {
    const headers = this.auth.setAuthHeaders();
    const subscriptionUrl = `${this.baseUrl}/api/subscription_order_wallet/create`;
    return this.http.post(subscriptionUrl, subscription, { headers })
    .pipe(
      map((response) => {
        console.log('Subscription Order Add successful:', response);
        // modal.close('Close click')
        // this.router.navigate(['/subscription']);
        return true;
      },
      (error) => {
        console.log('Subscription Order failed:', error);
        return of(false);
      })
    );
  }  

  getUserSubscriptionsOrder(user_detail_id: any): Observable<boolean> {

    const headers = this.auth.setAuthHeaders();
    const ServiceUrl = `${this.baseUrl}/api/subscription_order/${user_detail_id}`;
    return this.http.get<any>(ServiceUrl, { headers });
  }

  getUserSubscriptionsUpdate(membership_id: any): Observable<boolean> {

    const headers = this.auth.setAuthHeaders();
    const ServiceUrl = `${this.baseUrl}/api/subscription_order_update/${membership_id}`;
    // return this.http.put(ServiceUrl, { headers });
    return this.http.put(ServiceUrl, { headers })
      .pipe(
        map((response) => {
          console.log('Subscription Order Update successful:', response);
          return true;
        },
        (error) => {
          console.log('Subscription Order failed:', error);
          return of(false); 
        })
      );
  }

  getUserSubscriptionsHome(): Observable<boolean> {

    // const headers = this.auth.setAuthHeaders();
    const ServiceUrl = `${this.baseUrl}/api/subscription_home`;
    return this.http.get<any>(ServiceUrl);
  }

  getUserServicesHome(): Observable<boolean> {
    // const headers = this.auth.setAuthHeaders();
    const ServiceUrl = `${this.baseUrl}/api/service_home`;
    return this.http.get<any>(ServiceUrl);
  }
    // .pipe(
    //   map((response) => {
    //     console.log('Membership get succesfully:', response);
    //     return true;
    //   },
    //   (error) => {
    //     console.log('Membership Fetch Data failed:', error);
    //     return of(false);
    //   })
    // );


    getAllServiceFeatures(): Observable<any> {
      const headers = this.auth.setAuthHeaders();
      const ServiceFeatureUrl = `${this.baseUrl}/api/services-feature`;
      return this.http.get<any>(ServiceFeatureUrl, { headers });
    }

    createServiceFeature(servicefeature: any): Observable<boolean> {
      const headers = this.auth.setAuthHeaders();
      const ServiceFeatureUrl = `${this.baseUrl}/api/services-feature/create`;
      return this.http.post(ServiceFeatureUrl, servicefeature, { headers })
      .pipe(
        map((response) => {
          console.log('Service Feature Add successful:', response);
          return true;
        },
        (error) => {
          console.log('Service Feature failed:', error);
          return of(false);
        })
      );
    }

    updateServiceFeature(serviceFeature: any, service_feature_id: any): Observable<boolean> {
      const headers = this.auth.setAuthHeaders();
      const ServiceFeatureUrl = `${this.baseUrl}/api/services-feature/${service_feature_id}/update`;
      return this.http.put(ServiceFeatureUrl, serviceFeature, { headers })
      .pipe(
        map((response) => {
          console.log('Service Feature Update successful:', response);
          return true;
        },
        (error) => {
          console.log('Service Feature failed:', error);
          return of(false); 
        })
      );
    }

    deleteServiceFeature(service_feature_id: any): Observable<boolean> {
      const headers = this.auth.setAuthHeaders();
      const ServiceFeatureUrl = `${this.baseUrl}/api/services-feature/${service_feature_id}/delete`;
      return this.http.delete(ServiceFeatureUrl, { headers })
      .pipe(
        map((response) => {
          console.log('Service Feature Delete successful:', response);
          return true;
        },
        (error) => {
          console.log('Service Feature failed:', error);
          return of(false);
        })
      );
    }


    updateOrders(order: any, order_id: any): Observable<boolean> {
      const headers = this.auth.setAuthHeaders();
      const Url = `${this.baseUrl}/api/orders/${order_id}/update`;
      return this.http.put(Url, order, { headers })
      .pipe(
        map((response) => {
          console.log('Order Update successful:', response);
          // modal.close('Close click')
          // this.router.navigate(['/subscription']);
          // this.refreshCurrentRoute();
          return true;
        },
        (error) => {
          console.log('Order failed:', error);
          return of(false);
        })
      );
    }

    cancelOrders(order: any, order_id: any): Observable<boolean> {
      const headers = this.auth.setAuthHeaders();
      const Url = `${this.baseUrl}/api/orders/${order_id}/cancel`;
      return this.http.put(Url, order, { headers })
      .pipe(
        map((response) => {
          console.log('Order cancel successful:', response);
          // modal.close('Close click')
          // this.router.navigate(['/subscription']);
          // this.refreshCurrentRoute();
          return true;
        },
        (error) => {
          console.log('Order cancel failed:', error);
          return of(false);
        })
      );
    }

    blockOrders(order: any, order_id: any): Observable<boolean> {
      const headers = this.auth.setAuthHeaders();
      const Url = `${this.baseUrl}/api/orders/${order_id}/block`;
      return this.http.put(Url, order, { headers })
      .pipe(
        map((response) => {
          console.log('Order cancel successful:', response);
          // modal.close('Close click')
          // this.router.navigate(['/subscription']);
          // this.refreshCurrentRoute();
          return true;
        },
        (error) => {
          console.log('Order cancel failed:', error);
          return of(false);
        })
      );
    }

    cancelOrders2(order: any, order_id: any): Observable<boolean> {
      const headers = this.auth.setAuthHeaders();
      const Url = `${this.baseUrl}/api/orders/${order_id}/incomplete`;
      return this.http.put(Url, order, { headers })
      .pipe(
        map((response) => {
          console.log('Order cancel successful:', response);
          // modal.close('Close click')
          // this.router.navigate(['/subscription']);
          // this.refreshCurrentRoute();
          return true;
        },
        (error) => {
          console.log('Order cancel failed:', error);
          return of(false);
        })
      );
    }

    completeOrders(order: any, order_id: any): Observable<boolean> {
      const headers = this.auth.setAuthHeaders();
      const Url = `${this.baseUrl}/api/orders/${order_id}/complete`;
      return this.http.put(Url, order, { headers })
      .pipe(
        map((response) => {
          console.log('Order cancel successful:', response);
          // modal.close('Close click')
          // this.router.navigate(['/subscription']);
          // this.refreshCurrentRoute();
          return true;
        },
        (error) => {
          console.log('Order cancel failed:', error);
          return of(false);
        })
      );
    }


    updateOrders2(order: any, order_id: any): Observable<boolean> {
      const headers = this.auth.setAuthHeaders();
      const Url = `${this.baseUrl}/api/orders/${order_id}/update2`;
      return this.http.put(Url, order, { headers })
      .pipe(
        map((response) => {
          console.log('Order Update successful:', response);
          // modal.close('Close click')
          // this.router.navigate(['/subscription']);
          // this.refreshCurrentRoute();
          return true;
        },
        (error) => {
          console.log('Order failed:', error);
          return of(false);
        })
      );
    }


    reviewOrders(review: any, order_id: any): Observable<boolean> {
      const headers = this.auth.setAuthHeaders();
      const Url = `${this.baseUrl}/api/orders/${order_id}/review`;
      return this.http.put(Url, review, { headers })
      .pipe(
        map((response) => {
          console.log('Order Reviews successful:', response);
          // modal.close('Close click')
          // this.router.navigate(['/subscription']);
          // this.refreshCurrentRoute();
          return true;
        },
        (error) => {
          console.log('Order Review failed:', error);
          return of(false);
        })
      );
    }

}
