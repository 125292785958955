<div id="layout-wrapper">


  <div [ngSwitch]="userDetails?.group_choice">
    <div *ngSwitchCase="'admin'">
        <!-- start -->
        <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
        </app-topbar>
      
        <app-sidebar [isCondensed]="isCondensed"></app-sidebar>
      
        <div class="main-content">
          <div class="page-content">
            <!-- content -->
            <router-outlet></router-outlet>
          </div>
      
          <!-- footer -->
          <app-footer></app-footer>
        </div>
        <!-- End -->

    </div>
    <div *ngSwitchCase="'vendors'">

        <!-- start -->
        <app-topbar2 (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
        </app-topbar2>
      
        <app-sidebar2 [isCondensed]="isCondensed"></app-sidebar2>
      
        <div class="main-content">
          <div class="page-content">
            <!-- content -->
            <router-outlet></router-outlet>
          </div>
      
          <!-- footer -->
          <app-footer2></app-footer2>
        </div>
        <!-- End -->

    </div>
    <div *ngSwitchCase="'customers'">

        <!-- start -->
        <app-topbar3 (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
        </app-topbar3>
      
        <app-sidebar3 [isCondensed]="isCondensed"></app-sidebar3>
      
        <div class="main-content">
          <div class="page-content">
            <!-- content -->
            <router-outlet></router-outlet>
          </div>
      
          <!-- footer -->
          <app-footer3></app-footer3>
        </div>
        <!-- End -->

    </div>
    <div *ngSwitchDefault>
      <div>{{ 'DASHBOARD_NOT' | translate }}</div>
    </div>
  </div>
  

  <app-rightsidebar></app-rightsidebar>

</div>


