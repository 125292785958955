import { Component, OnInit, HostListener } from '@angular/core';

import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { SubsctiptionService } from '../../core/services/subscription.service';
import { JwtAuthService } from '../../core/services/auth.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from '../../core/services/language.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})

/**
 * PAges-404 component
 */
export class HomePageComponent implements OnInit {

  subscriptionsDetails: any;
  serviceDetails: any;
  imagePaths = ['1.jpg', '2.jpg', '3.jpg'];
  isNavbarCollapsed = true;

  countryName;
  cookieValue;
  flagvalue;
  valueset;

  screenWidth: number = window.innerWidth;
  dropdownClass: string = this.screenWidth < 992 ? 'dropdown-menu-start' : 'dropdown-menu-end';
  mainClass: string = this.screenWidth < 992 ? 'ms-left' : 'ms-auto';

  constructor( private subscriptionService: SubsctiptionService,
    private authService: JwtAuthService, public languageService: LanguageService,
    private scrollToService: ScrollToService, public _cookiesService: CookieService) { 
      this.serviceDetails = {};
      this.subscriptionsDetails = {};
    }

    listLang = [
      { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
      { text: 'Arabic', flag: 'assets/images/flags/arabic.jpg', lang: 'ar' },
    ];

  ngOnInit(): void {

        this.cookieValue = this._cookiesService.get('lang');
        const val = this.listLang.filter(x => x.lang === this.cookieValue);
        this.countryName = val.map(element => element.text);

        if (val.length === 0) {
          if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
        } else {
          this.flagvalue = val.map(element => element.flag);
        }
    
        this.subscriptionService.getUserSubscriptionsHome().subscribe(
          subscriptionsDetails => {
            this.subscriptionsDetails = subscriptionsDetails;

            this.subscriptionsDetails = this.subscriptionsDetails.sort((a, b) => a.price - b.price);
                       
            console.log(this.subscriptionsDetails);
            
          },
            error => {
              console.log(error);
              // Handle the error
            }
          );

          this.subscriptionService.getUserServicesHome().subscribe(
            serviceDetails => {
              this.serviceDetails = serviceDetails;
                         
              console.log(this.serviceDetails);
              
            },
              error => {
                console.log(error);
                // Handle the error
              }
            );
     
   
  }

  public scrollTo(elementId: string): void {
    this.scrollToService.scrollTo({ target: elementId });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.screenWidth = (event.target as Window).innerWidth;
    this.dropdownClass = this.screenWidth < 992 ? 'dropdown-menu-start' : 'dropdown-menu-end';
  }
  
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }


    /**
   * Toggle the menu bar when having mobile screen
   */
    // toggleMobileMenu(event: any) {
    //   event.preventDefault();
    //   this.mobileMenuButtonClicked.emit();
    // }

}
