import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARD',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARD',
        icon: 'bx-home-circle',
        link: '/dashboard',
    },
    {
        id: 3,
        label: 'MENUITEMS.ADDRESS',
        link: '/dashboard/address',
        icon: 'bx-map',
    },
    {
        id: 20,
        label: 'HEADER.WALLET',
        icon: 'bx-wallet',
        link: '/dashboard/wallet',
    },
    // {
    //     id: 6,
    //     label: 'MENUITEMS.MESSAGES',
    //     icon: 'bx-chat',
    //     link: '/dashboard/message',
    // },
    {
        id: 7,
        isLayout: true
    },
    {
        id: 8,
        label: 'MENUITEMS.MAIN',
        isTitle: true,
    },
    {
        id: 9,
        label: 'MENUITEMS.USERS',
        link: '/dashboard/users',
        icon: 'bx-user-plus',
    },
    {
        id: 9,
        label: 'MENUITEMS.SUBSCRIPTION',
        icon: 'bx-task',
        link: '/dashboard/subscription',
    },
    {
        id: 13,
        label: 'MENUITEMS.SERVICES',
        link: '/dashboard/service',
        icon: 'bx-briefcase-alt',
    },
];

