import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.CUSTOMERS',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARD',
        icon: 'bx-home-circle',
        link: '/dashboard',
    },
    {
        id: 3,
        label: 'MENUITEMS.ADDRESS',
        link: '/dashboard/address',
        icon: 'bx-map',
    },
    {
        id: 20,
        label: 'HEADER.WALLET',
        icon: 'bx-wallet',
        link: '/dashboard/wallet',
        
    },
    {
        id: 21,
        label: 'HEADER.WISHLIST',
        icon: 'bx-grid-alt',
        link: '/dashboard/wishlist',
        
    },
    // {
    //     id: 6,
    //     label: 'MENUITEMS.MESSAGES',
    //     icon: 'bx-chat',
    //     link: '/message',
    // },
    {
        id: 7,
        isLayout: true
    },
    {
        id: 8,
        label: 'MENUITEMS.ZOHDY',
        isTitle: true,
    },
    {
        id: 9,
        label: 'MENUITEMS.BOOKING',
        icon: 'bx-calendar',
        link: '/dashboard/booking',
    },
    {
        id: 11,
        label: 'MENUITEMS.BOOKING-DETAILS',
        icon: 'bx-grid-alt',
        link: '/dashboard/booking-detail',
    },
];

