import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.VENDORS',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARD',
        icon: 'bx-home-circle',
        link: '/dashboard',
    },
    {
        id: 3,
        label: 'MENUITEMS.ADDRESS',
        link: '/dashboard/address',
        icon: 'bx-map',
    },
    {
        id: 14,
        label: 'MENUITEMS.AVAILABLE',
        icon: 'bxs-hourglass',
        link: '/dashboard/available',
    },
    {
        id: 20,
        label: 'HEADER.WALLET',
        icon: 'bx-wallet',
        link: '/dashboard/wallet',
    },
    // {
    //     id: 6,
    //     label: 'MENUITEMS.MESSAGES',
    //     icon: 'bx-chat',
    //     link: '/dashboard/message',
    // },
    {
        id: 7,
        isLayout: true
    },
    {
        id: 8,
        label: 'MENUITEMS.ZOHDY',
        isTitle: true,
    },
    {
        id: 9,
        label: 'MENUITEMS.MEMEBRSHIP',
        icon: 'bx-calendar',
        subItems: [
            {
                id: 10,
                label: 'MENUITEMS.MEMEBRSHIP_LEVEL',
                link: '/dashboard/membership',
                parentId: 9
            },
            {
                id: 11,
                label: 'MENUITEMS.SUBSCRIPTION_STATUS',
                link: '/dashboard/subscription-status',
                parentId: 9
            },
            
        ]
    },
    {
        id: 12,
        label: 'MENUITEMS.VENDORS_ORDERS',
        icon: 'bx-loader',
        link: '/dashboard/vendors-order',
    },
    {
        id: 13,
        label: 'MENUITEMS.ORDERS_ACCEPT',
        icon: 'bx-user-check',
        link: '/dashboard/order-accept',
    },
    {
        id: 14,
        label: 'MENUITEMS.ORDERS_DONE',
        icon: 'bx-check-square',
        link: '/dashboard/order-done',
    },
    
];

