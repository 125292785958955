<!-- <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <div class="navbar-brand-box">
          <a routerLink="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="assets/images/logo.svg" alt="" height="22">
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-dark.png" alt="" height="17">
            </span>
          </a>

          <a routerLink="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="assets/images/logo-light.svg" alt="" height="22">
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-light.png" alt="" height="19">
            </span>
          </a>
        </div>

        <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
            <i class="fa fa-fw fa-bars"></i>
        </button>


        <div class="d-none d-lg-block me-2" >
            <button type="button" class="btn header-item">
              Home
            </button>
        </div>

        <div class="d-none d-lg-block me-2" >
            <button type="button" class="btn header-item">
                Service
            </button>
        </div>
        <div class="d-none d-lg-block me-2" >
            <button type="button" class="btn header-item" (click)="scrollTo('pricing')">
                Pricings
            </button>
        </div>

        <div class="d-none d-lg-block me-2">
            <button type="button" class="btn header-item" routerLink="/account/login">
                Login
            </button>
        </div>

        <div class="d-none d-lg-block me-2" >
            <button type="button" class="btn header-item" routerLink="/account/signup">
                Signup
            </button>
        </div>
       
      
        <form class="app-search d-none d-xl-block">
            <div class="position-relative">
              <input type="text" class="form-control" placeholder="Search Service">
              <span class="bx bx-search-alt"></span>
            </div>
        </form>


    </div>
    </div>
</header> -->

<nav class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
  <div class="container-fluid">
    <a class="navbar-brand" href="/"><img src="assets/images/logo-light.png" alt="" height="22" class="d-inline-block align-text-top"></a>
    <button class="navbar-toggler" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed"
            [attr.aria-expanded]="!isNavbarCollapsed" aria-controls="navbarNav" [attr.aria-label]="isNavbarCollapsed ? 'Close menu' : 'Open menu'">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link">{{ 'HOME' | translate }}</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="scrollTo('service')">{{ 'SERVICE' | translate }}</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="scrollTo('pricing')">{{ 'PRICING' | translate }}</a>
        </li>
        
      </ul>
      <ul class="navbar-nav ms-auto" >

        <!-- <div class="dropdown d-inline-block" ngbDropdown>
          <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
            <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="14">
            <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="14">
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            
            <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
              (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
              <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
                class="align-middle">{{item.text}}</span>
            </a>
            
          </div>
        </div> -->

        <li class="nav-item dropdown" ngbDropdown>
          <a class="nav-link dropdown-toggle" id="languageDropdown" role="button" ngbDropdownToggle>
            <img *ngIf="flagvalue !== undefined" [src]="flagvalue" alt="Header Language" height="14">
            <img *ngIf="flagvalue === undefined" [src]="valueset" alt="Header Language" height="14">
          </a>
          <ul class="dropdown-menu" aria-labelledby="languageDropdown" ngbDropdownMenu>
            <li *ngFor="let item of listLang">
              <a href="javascript:void(0);" class="dropdown-item notify-item" (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
                <img [src]="item.flag" alt="user-image" class="me-1" height="12"> <span class="align-middle">{{item.text}}</span>
              </a>
            </li>
          </ul>
        </li>
              
        <ul class="navbar-nav" [ngClass]="mainClass">
          <li class="nav-item dropdown" ngbDropdown>
            <a class="nav-link dropdown-toggle" id="signupDropdown" role="button" ngbDropdownToggle>
              {{ 'LOGIN' | translate }}
            </a>
            <ul class="dropdown-menu" [ngClass]="dropdownClass" aria-labelledby="signupDropdown" ngbDropdownMenu>
              <li>
                <a class="dropdown-item" routerLink="/account/login" [queryParams]="{tab: 1}">{{ 'LOGIN_CUSTOMER' | translate }}</a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/account/login" [queryParams]="{tab: 2}">{{ 'LOGIN_VENDOR' | translate }}</a>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="navbar-nav" [ngClass]="mainClass">
          <li class="nav-item dropdown" ngbDropdown>
            <a class="nav-link dropdown-toggle" id="signupDropdown" role="button" ngbDropdownToggle>
              {{ 'SIGNUP' | translate }}
            </a>
            <ul class="dropdown-menu" [ngClass]="dropdownClass" aria-labelledby="signupDropdown" ngbDropdownMenu>
              <li>
                <a class="dropdown-item" routerLink="/account/signup" [queryParams]="{tab: 1}">{{ 'SIGNUP_CUSTOMER' | translate }}</a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/account/signup" [queryParams]="{tab: 2}">{{ 'SIGNUP_FREELANCER' | translate }}</a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/account/signup" [queryParams]="{tab: 3}">{{ 'SIGNUP_VENDOR' | translate }}</a>
              </li>
            </ul>
          </li>
        </ul>
        
          
      </ul>
    </div>
  </div>
</nav>



<div class="container-fluid">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <ngb-carousel>
                    <ng-template ngbSlide *ngFor="let item of (serviceDetails | keyvalue | slice:0:3); let i = index">
                    <img src="assets/images/coursal/{{ imagePaths[i] }}" alt="slide {{ item.value.name }}" class="d-block img-fluid w-100">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 class="text-white">{{ item.value.name }}</h5>
                        <p>{{ item.value.description }}</p>
                        <button type="button" class="btn btn-outline-secondary text-white" routerLink="/account/login">
                            {{ 'BOOK_NOW' | translate }}
                        </button>
                    </div>
                    </ng-template>
                </ngb-carousel>
            </div>
        </div>
    </div>
</div>
  

<div class="container" id="service">
    <div class="container-fluid" id="services" style="padding-top: 70px;">
    <h1 class="text-center text-primary">{{ 'SERVICES' | translate }}</h1>
    <p class="text-center">
        {{ 'DES_1' | translate }}
    </p>
  
    <div class="row">
      <ng-container *ngFor="let service of serviceDetails | keyvalue">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header bg-secondary text-white">
                <h4 class="card-title mb-0">
                  <strong>{{ 'ZOHDY_SERVICES' | translate }}</strong>
                </h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-9">
                  <h3>{{ service.value.name }}</h3>
                  <p>{{ service.value.description }}</p>
                </div>
                <div class="col-lg-3">
                  <div class="d-flex justify-content-end">
                    <h3 class="mb-0">{{ service.value.priceperhour }} DHS</h3>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <button type="button" class="btn btn-lg btn-outline-primary rounded-pill float-end" routerLink="/account/login">{{ 'BOOK_NOW' | translate }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="container" id="pricing" style="padding-top: 70px;">

    <h1 class="text-center text-primary">{{ 'PRICING' | translate }}</h1>
    <p class="text-center">
        {{ 'DES_2' | translate }}
    </p>

    <div class="row">
        <ng-container *ngFor="let subscription of subscriptionsDetails | keyvalue">
            <div class="col-12 col-sm-6 col-lg-4" *ngIf="subscription.value.status === true">
                <div class="card plan-pricing text-center p-0">
                  <div class="card-header bg-secondary text-white">
                    <h4 class="card-title mb-0">
                      <strong>{{ subscription.value.name }}</strong>
                    </h4>
                  </div>
                  <div class="card-body">
                    <h3><strong>{{ subscription.value.name }}</strong></h3>
                    <ul class="list-unstyled mb-4">
                      <li><i class="fas fa-hourglass-start"></i>&nbsp; {{ 'TOTAL_HOURS' | translate }} ({{ subscription.value.hours }})</li>
                      <li><i class="fas fa-calendar-day"></i>&nbsp; {{ 'VALIDITY' | translate }} ({{ subscription.value.validity_period }} {{ subscription.value.validity_period_unit }})</li>
                      <li><i class="fas fa-money-bill-alt"></i>&nbsp; {{ 'PRICE' | translate }} {{ subscription.value.price }} DHS</li>
                    </ul>
                    <button type="button" class="btn btn-lg btn-outline-primary rounded-pill" routerLink="/account/login">{{ 'BUY_NOW' | translate }}</button>
                  </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>




<footer class="bg-dark text-white py-3">
    <div class="container">
      <!-- <div class="row">
        <div class="col-12 col-md-4 text-center">
          <img src="assets/images/logo-light.png" alt="" height="50" class="d-inline-block align-text-top">
          <p class="mt-3">Zohdy is an online marketplace that provides quotes and online bookings home services, baquest and driving services, through an extensive network. On zohdy, customers can read detailed service offerings, with minimum as low as 25 DHS.</p>
        </div>
        <div class="col-6 col-md-4" >
          <h5 class="text-uppercase">Quick Links</h5>
          <ul class="list-unstyled">
            <li><a href="/">Home</a></li>
            <li><a (click)="scrollTo('service')">Service</a></li>
            <li><a (click)="scrollTo('pricing')">Pricing</a></li>
            <li><a href="/account/login">Login</a></li>
            <li><a href="/account/signup">Signup</a></li>
          </ul>
        </div>
        <div class="col-6 col-md-4 footer-box">
            <h4 class="footer-box-title"><b>Contact</b></h4>
            <ul class="list-unstyled">
              <li class="d-flex align-items-center mb-2">
                <i class="far fa-envelope text-accent me-2"></i>
                <div>
                  <h5><b>Email: </b></h5>
                  <p>info@zohdy.com</p>
                </div>
              </li>
              <li class="d-flex align-items-center">
                <i class="fas fa-location-arrow text-accent me-2"></i>
                <div>
                  <h5><b>Address</b></h5>
                  <p>Hamdan Street,</p>
                  <p>Abudhabi</p>
                </div>
              </li>
            </ul>
        </div>
      </div> -->
      <div class="row">
        <div class="col-12 col-md-4 text-center">
          <img src="assets/images/logo-light.png" alt="" height="50" class="d-inline-block align-text-top">
          <p class="mt-3">{{ 'ZOHDY_P1' | translate }}</p>
        </div>
        <div class="col-6 col-md-4">
          <h5 class="text-uppercase">{{ 'QUICK_LINKS' | translate }}</h5>
          <ul class="list-unstyled">
            <li><a href="/">{{ 'HOME' | translate }}</a></li>
            <li><a href="#service">{{ 'SERVICE' | translate }}</a></li>
            <li><a href="#pricing">{{ 'PRICING' | translate }}</a></li>
            <li><a href="/account/login">{{ 'LOGIN' | translate }}</a></li>
            <li><a href="/account/signup">{{ 'SIGNUP' | translate }}</a></li>
          </ul>
        </div>
        <div class="col-6 col-md-4">
          <h5 class="text-uppercase">{{ 'CONTACT' | translate }}</h5>
          <p> </p>
          <ul class="list-unstyled">
            <li class="d-flex align-items-center mb-2">
              
              <div>
                <h6><i class="far fa-envelope text-accent me-2"></i><b>{{ 'EMAIL' | translate }}:</b> info@zohdy.com</h6>
              </div>
            </li>
            <li class="d-flex align-items-center">
              
              <div>
                <h6><i class="fas fa-location-arrow text-accent me-2"></i><b>{{ 'ADDRESS1' | translate }}:</b> {{ 'ADDRESS_PATH' | translate }}</h6>
              </div>
            </li>
          </ul>
        </div>
        <!-- <div class="col-6 col-md-4">
          <h5 class="text-uppercase">Contact</h5>
          <ul class="list-unstyled">
            <li>
              <div class="d-flex align-items-center">
                <i class="far fa-envelope text-accent me-2"></i>
                <div>
                  <p class="mb-0"><b>Email:</b> 
                    info@zohdy.com</p>
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex align-items-center">
                <i class="fas fa-location-arrow text-accent me-2"></i>
                <div>
                  <p class="mb-0"><b>Address:</b> 
                    Hamdan Street, Abu Dhabi</p>
                </div>
              </div>
            </li>
          </ul>
        </div> -->
      </div>
          
      <hr>
      <div class="row">
        <div class="col-12 text-center">
          <p class="mb-0">&copy; {{ 'ALL_RIGHT' | translate }}</p>
        </div>
      </div>
    </div>
</footer>
  


