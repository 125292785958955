import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { JwtAuthService } from '../../core/services/auth.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';

import { SubsctiptionService } from '../../core/services/subscription.service';
import { addHours, parseISO, format } from 'date-fns';


@Component({
  selector: 'app-topbar2',
  templateUrl: './topbar2.component.html',
  styleUrls: ['./topbar2.component.scss'],
  template: `
    <img [src]="imageUrl">
  `,
})

/**
 * Topbar component
 */
export class Topbar2Component implements OnInit {

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;

  imageUrl: string;

  membershipDetails: any;
  cities: any;
  serviceName: any;
  orderDetails: any[]= [];
  addressDetails: any[] = [];
  availableDetails: any = [];
  acceptOrderDetails: any[]= [];
  walletDetails: any = [];
  hour_used = 0;

  vendor_order_total: number = 0;
  vendor_order_accepted: number = 0;
  membership_time_available: number = 0;
  wallet_money: number = 0;

  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: JwtAuthService,
              public languageService: LanguageService,
              public translate: TranslateService,
              private serviceService: SubsctiptionService,
              public _cookiesService: CookieService) {
                this.userDetails = {};
                this.imageUrl = authService.getImageUrl();
                this.vendor_order_total = 0;
                this.vendor_order_accepted = 0;
                this.membership_time_available = 0;
                this.wallet_money = 0;
  }

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Arabic', flag: 'assets/images/flags/arabic.jpg', lang: 'ar' },
  ];


  openMobileMenu: boolean;
  userDetails: any;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    // this.authService.getUserDetails().subscribe(
    //   userDetails => {
    //     this.userDetails = userDetails;
    //     // console.log(this.userDetails);
    //     this.userDetails.image = this.imageUrl + this.userDetails.image;
    //     // Do something with the user details
    //   },
    //   error => {
    //     console.log(error);
    //     // Handle the error
    //   }
    // );

    this.authService.getOrdersDetails().subscribe(
      orderDetails => {
        // console.log("this.orderDetails");
        this.orderDetails = orderDetails;
        // console.log(this.orderDetails);
        
      },
        error => {
          console.log(error);
          // Handle the error
        }
      );

      this.authService.getUserDetails().subscribe(
        userDetails => {
          this.userDetails = userDetails;
          this.userDetails.image = this.imageUrl + this.userDetails.image;
          // console.log("User Details");
          this.serviceName = this.userDetails.services_selected.split(';');
          // console.log(this.serviceName);
          // console.log(this.userDetails);
          this.authService.getUserAddress(this.userDetails.id).subscribe(
            addressDetails => {
              this.addressDetails = addressDetails;
              // console.log("this.address Details");
              // console.log(this.addressDetails);
              this.cities = [...new Set(this.addressDetails.map(address => address.city))];
              // console.log(this.cities);
              // console.log(this.cities);
  
              this.serviceService.getUserSubscriptionsOrder(this.userDetails.id).subscribe(
                membershipDetails => {
                  this.membershipDetails = membershipDetails;
                  // console.log("membership details");
                  this.membershipDetails.forEach(member => {
                    if ((member.subscription.hours - member.hours_used <= 0 || this.isEndDatePast(member.end_date)) && member.status === 'active') {
                        // this.changeStatusMembership(member.id);
                        this.serviceService.getUserSubscriptionsUpdate(member.id).subscribe();
                        // console.log(member.id);
                    }
                    else if((member.subscription.hours - member.hours_used > 0 && !this.isEndDatePast(member.end_date)) && member.status === 'active'){
                      // console.log("member ok");
                      // console.log(member);
                    }
                  });
                  // console.log("membership details", this.membershipDetails);
  
                  this.authService.getUserAvailable(this.userDetails.id).subscribe(
                    availableDetails => {
                      // console.log("this.availableDetails");
                      this.availableDetails = availableDetails;
                      // console.log(this.availableDetails);
  
                      this.authService.getAcceptOrdersDetails(this.userDetails.id).subscribe(
                        acceptOrderDetails => {
                          // console.log("this.acceptOrderDetails");
                          this.acceptOrderDetails = acceptOrderDetails;
                          // console.log(this.acceptOrderDetails);
                          this.acceptOrderDetails.forEach(order_accept => {
                            if (order_accept.order_status === "active"){
                              // console.log(this.hour_used);
                              this.hour_used += order_accept.hours_needed;
                            }
                          });
                          //////////////////
                          console.log("Testin 0");
                          this.vendor_order_total = 0;
                          for (const order of this.orderDetails) {
                            for (const city of this.cities) {
                              if (
                                order.order_status === 'pending' &&
                                this.userDetails?.profile?.user_type === 'company_vendor' &&
                                order?.address?.city === city &&
                                this.serviceName.includes(order.service.name) &&
                                this.isTimeAvailable(order.order_time, order.hours_needed, order.order_day)
                              ) {
                                this.vendor_order_total += 1;
                              } else if (
                                order.order_status === 'pending' &&
                                this.userDetails?.profile?.user_type === 'freelancer' &&
                                order?.cleaners_needed === 1 &&
                                order?.address?.city === city &&
                                order?.gender === this.userDetails?.gender &&
                                this.serviceName.includes(order.service.name) &&
                                this.isTimeAvailable(order.order_time, order.hours_needed, order.order_day)
                              ) {
                                this.vendor_order_total += 1;
                              }
                            }
                          }

                          

                          this.vendor_order_accepted = 0;
                          for (const order of this.orderDetails) {
                            if (order.order_status === 'accepted'){
                              this.vendor_order_accepted += 1;
                            }
                          }

                          if (this.isMembershipHourLeft()){
                            this.membership_time_available = 1;
                          } else {
                            this.membership_time_available = 0;
                          }

                          console.log("Total Order ");
                          console.log(this.vendor_order_total);
                          console.log("Total Order Accepted");
                          console.log(this.vendor_order_accepted);
                          console.log("Total membership hour left");
                          console.log(this.membership_time_available);
                          /////
                          
                        },
                          error => {
                            console.log(error);
                            // Handle the error
                          }
                      );
  
                      
                    },
                      error => {
                        console.log(error);
                        // Handle the error
                      }
                    );
                  
                },
                  error => {
                    console.log(error);
                    // Handle the error
                  }
                );
              
            },
              error => {
                console.log(error);
                // Handle the error
              }
            );
          // Do something with the user details
  
          this.authService.getUserWallet(this.userDetails.id).subscribe(
          walletDetails => {
            console.log("this.walletDetails");
            this.walletDetails = walletDetails;
            console.log(this.walletDetails);
            if (parseFloat(this.walletDetails.balance) > 30){
              this.wallet_money = 0;
            } else {
              this.wallet_money = 1;
            }
          },
            error => {
              console.log(error);
              // Handle the error
              if(this.walletDetails.length === 0){
                // console.log("ok");
                const formData = new FormData();
                formData.append('created_by_wallet.email', this.userDetails.email);
                this.authService.createWallet(formData).subscribe(
                  (result: boolean) => {
                    console.log('Vendor Wallet Created:', result);
                    this.ngOnInit();
                    
                  },
                  (error) => {
                    console.log('Vendor Wallet Create failed:', error);
                  }
                );
              } 
            }
          );
          
        },
        error => {
          console.log(error);
          // Handle the error
        }
      );


      




  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    if (environment.defaultauth === 'firebase') {
      this.authService.logout();
    } else {
      this.authService.logout();
    }
    this.router.navigate(['/account/login']);
  }

  isEndDatePast(endDate: string): boolean {
    return new Date(endDate) < new Date();
  }

  getEndTime(orderTime: string, hoursNeeded: number): string {
    const dateObject = parseISO('1970-01-01T' + orderTime + 'Z');  // Assuming your time is in HH:mm:ss format
    const endDate = addHours(dateObject, hoursNeeded);
    return endDate.toISOString().substr(11, 8); // Extract time portion in HH:mm:ss format
  }

  getDay(date: string){
    const givenDate = new Date(date);
    const formattedDate = format(givenDate, 'EEEE');
    return formattedDate;
  }

  isTimeAvailable(orderTime: string, hoursNeeded: number, date: string): boolean {
    // console.log(orderTime);
    // console.log(hoursNeeded);
    // console.log(date);

    // console.log(this.availableDetails);

    const orderDate = new Date(date);
    const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const day = days[orderDate.getDay()];

    // Fetch the start and end times for that day
    const startTimeStr = this.availableDetails[`${day}_start`];
    const endTimeStr = this.availableDetails[`${day}_end`];

    if (!startTimeStr || !endTimeStr) {
      // If either start or end time is null for that day, return false
      return false;
    }
    // Convert orderTime and hoursNeeded to minutes since midnight
    const [orderHour, orderMin] = orderTime.split(':').map(Number);
    const orderMinutes = orderHour * 60 + orderMin;
    const orderEndMinutes = orderMinutes + hoursNeeded * 60;

    // Convert start and end times to minutes since midnight
    const [startHour, startMin] = startTimeStr.split(':').map(Number);
    const [endHour, endMin] = endTimeStr.split(':').map(Number);
    const startMinutes = startHour * 60 + startMin;
    const endMinutes = endHour * 60 + endMin;

    // Check if the order time plus hours needed falls within the available time
    return orderMinutes >= startMinutes && orderEndMinutes <= endMinutes;
  }

  isMembershipHourLeft(): boolean {
    let combinedHours = 0;

    for (let member of this.membershipDetails) {
        if (member.status === 'active' && !this.isEndDatePast(member.end_date)) {
          combinedHours += member.subscription.hours - member.hours_used;
        } else if (member.status === 'accepted' && !this.isEndDatePast(member.end_date)) {
          combinedHours += member.subscription.hours - member.hours_used;
        }
    }
    console.log("combined hours");
    console.log(combinedHours);
    combinedHours = combinedHours - this.hour_used;
    console.log(combinedHours);
    if (combinedHours <= 10) { 
        return true;
    }
    return false;
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
}
