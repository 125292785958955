import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { JwtAuthService } from '../services/auth.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: JwtAuthService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot, 
        state: RouterStateSnapshot
        ): Observable<boolean> {
        
          const accessTypes = next.data['message'];
          console.log(next);
          console.log(accessTypes);
          const token = this.authService.getToken();
          if (token && !this.authService.jwtHelper.isTokenExpired(token)) {
          // Token is valid, so allow access
          
          
            return this.authService.getUserDetails().pipe(
              map(userDetails => {
                  console.log("Done --------");
                  console.log(userDetails);
                  console.log(userDetails.group_choice);
                  // Check if the user's group choice matches any of the access types specified in the route's data object
                  
                  return true;
                  // if (accessTypes && accessTypes.includes(userDetails.group_choice)) {
                    
                    // Allow access to the page
                    
                  // } else {
                  //   console.log("Access denied: User does not have permission to access this page.");
                  //   return false;
                  // }
              }),
              catchError(error => {
                console.log(error);
                // Handle the error
                return of(false);
              })
            );
          }
        
        // Token is invalid or not present, so redirect to login page with the return url
        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        return of(false);
      }
            

    
}
